/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DetailHeader from './header';
import { Modal, Table } from 'react-bootstrap'
import opensea from '../../img/opensea.svg'
import { queryNft, searchNft, filterNft, refreshOpensea} from '../../axios/api';
import {OverlayTrigger,Popover} from 'react-bootstrap'
import setIconUrl from '../../utils';
import './index.css'
import PriceComp from './price';
import { NxWeb3 } from '../../lib/NxWeb3';
import { Order } from 'opensea-js/lib/types';
import loading from '../Loading/loading';
import CallBackNews from '../notification/call'


function DeatilComp() {
	let location = useLocation();
	const routeParams:any = useParams();
	const ruleName = routeParams.name; // 获取ruleName，即获取项目名称
	const productId = routeParams.id; // 获取ruleName，即获取项目名称
	const [Data, setData] = useState<any>({nfts:[],contract_address:''}) // 保存NFT列表相关数据
	// const [productId, setproductId] = useState('')
	const [pageData, setPage] = useState({page:1,limit: 50, total: 0}) // 分页相关数据
	let Timer: NodeJS.Timeout; // 自动刷新计时器
	const [pauseTimer, setPauseTimer] = useState(false)
	const [rankTimer, setRankTimer] = useState(false)
	const [Loading, setLoading] = useState(false)
	const [seacrchTkoenId, setSearchTonkenId] = useState('')
	const [modalShow, setModalShow] = useState(false);  // 控制NFT详情弹窗
	const [selectData, setSelectData] = useState<any>({traits: []}); // 保存打开NFT的数据
	const [traitsData, setTraitsData] = useState<any>({attributes: []}); // 保存获取到的NFT的traits
	const [filterRank, setFilterRank] = useState({max_rank:'', min_rank:'',min_price: '', max_price: '', sort_price: ''})
	const [Revealed, setRevealed] = useState(0)
	const [Records, setRecords] = useState(0)

	// 获取NFT列表
	// const getData = async ()=> {
	// 	const params = {
	// 		name: ruleName,
	// 		page: Number(pageData.page -1),
	// 		limit: pageData.limit
	// 	}
	// 	setLoading(true)
	// 	await apiDetail(params).then(res => { 
	// 		// 获取数据成功后的其他操作
	// 		setLoading(false)
	// 		setData(res.data);
	// 		// setproductId(res.data.product_id)
	// 		setPage({...pageData, page: Number(res.data.page +1), limit: res.data.limit, total:res.data.total_pages})
	// 	}).catch(err => {
	// 		console.log(err)
	// 	})
	// }

	
	useEffect(()=>{
		if(!pauseTimer) {
			// getData();
			getFilterNft()
			Timer = setInterval(() => {
				// getData()
				getFilterNft()
			}, 5000)
		} else {
			clearInterval(Timer)
		} ;
		return ()=>{
			clearInterval(Timer)
		}
	}, [location.pathname, pageData.limit, pageData.page, ruleName, rankTimer, seacrchTkoenId, filterRank])


	// 购买
	const buyNft = async (n) =>{
		loading.show()
		if(!NxWeb3.instance.opseaConnect) return;
	
		try {
			let order:Order =  await NxWeb3.instance.opseaConnect.api.getOrder({ 
				// side: OrderSide.Sell,
				asset_contract_address: Data.contract_address,
				token_id: n.token_id,
				bundled: false,
				include_invalid: false,
				limit: 20,
				offset: 0
			})
			const accountAddress = NxWeb3.instance.accountAddress // The buyer's wallet address, also the taker
			try {
				const transactionHash =  await NxWeb3.instance.opseaConnect.fulfillOrder({ order, accountAddress })
				console.log(transactionHash)
				loading.hide();
			} catch (error:any) {
				loading.hide();
				let data = {
					msg : `${error.message}`,
					type : 'error',
					className: 'asd',
					duration:  3000,
				}
				CallBackNews.NewsSend(data);
			}
		} catch (error) {
			console.log('getOrder', error)
			loading.hide();
			let data = {
				msg : `${error}`,
				type : 'error',
				className: 'asd',
				duration:  3000,
			}
			CallBackNews.NewsSend(data);
		}

	}

	
	// 获取NFT详情Traits
	async function getNFTData(props){
		let params ={
			product_id: props.product_id,
			token_id: props.token_id
		}
		await queryNft(params).then(res => { 
			// 获取数据成功后的其他操作
			setTraitsData(res.data)
		}).catch(err => {
			console.log(err)
		})
	}

	// nft详情天弹窗
	function openDetail(data){
		getNFTData(data)
		setModalShow(true)
		setSelectData(data)
	}

	//详情页数据改变返回， 刷新数据请求
	const callBackCurrentIndex= useCallback((page, limit, type)=>{ 
		const paginationData:any = {
			page: page,
			limit: limit,
			total: pageData.total
		}
		setPage(paginationData)

		if(type === 'reset'){
			setPauseTimer(false)
			setSearchTonkenId('')
			setRankTimer(false)
		}
	},[pageData])



	// 筛选
	async function searchNftFun(){
		let params ={
			data: {
				product_id: productId,
				token_ids: seacrchTkoenId.split(',')
			}
		}
		setLoading(true)
		await searchNft(params).then(res => { 
			setLoading(false)
			console.log(res)
			if(res.code === 400) {
				let data = {
					msg : `${res.error.msg}`,  
					type : 'error',
					className: 'asd',
					duration:  3000,
				}
				CallBackNews.NewsSend(data);
				return
			}
			setData({...Data, nfts: res.data.nfts})
			setPage({...pageData, total: Math.ceil(res.data.count/pageData.limit)})  // Math.ceil(res.data.count/pageData.newData.limit) 进一法
		}).catch(err => {
			console.log(err)
		})
	}
	// 获取搜索框数据
	const getSearchTokenId = (e:any)=>{
		setSearchTonkenId(e.target.value)
	}

	const getSearchData = ()=>{
		if(seacrchTkoenId === "") {
			setPauseTimer(false)
			getFilterNft()
		} else {
			setPauseTimer(true)
			searchNftFun()
		}
	}

	const searchPress = (e)=>{
		if (e.which !== 13) return
		getSearchData()
	}

	// 筛选rank和price
	const getFilterNft = async ()=> {
		console.log(pageData)
		if(productId === '') return
		const params = {
			product_id: productId,
			max_rank: filterRank.max_rank||undefined,
			min_rank: filterRank.min_rank||undefined,
			max_price: filterRank.max_price||undefined,
			min_price: filterRank.min_price||undefined,
			page: Number(pageData.page -1),
			limit: pageData.limit,
			sort_price: filterRank.sort_price || undefined,
		}
		setLoading(true)
		await filterNft(params).then(res => { 
			setLoading(false)
			// 获取数据成功后的其他操作
			if(res.code === 400) {
				let data = {
					msg : `${res.error.msg}`,  
					type : 'error',
					className: 'asd',
					duration:  3000,
				}
				CallBackNews.NewsSend(data);
				return
			}
			setData(res.data)
			setRevealed(res.data.num)
			setRecords(res.data.total_nft)
			setPage({...pageData, page: Number(res.data.page +1), limit: res.data.limit, total:res.data.total_pages})
		}).catch(err => {
			console.log(err)
		})
	}

	// rank重置按钮
	const rankReset = ()=>{
		setFilterRank({...filterRank, max_rank: '', min_rank: ''})
		setPage({...pageData, page: 1})
		getFilterNft()
	}
	// rank应用按钮
	const rankApply = ()=>{
		setPage({...pageData, page: 1})
		setRankTimer(true)
		getFilterNft()
	}
	// rank修改值
	const changeInputval = (e, type)=>{
		setFilterRank({...filterRank, [type]: e.target.value})
	}


	// 价格筛选返回
	const callFilterPrice = useCallback((val) => {
		console.log(JSON.stringify(filterRank) !== JSON.stringify(val), val, filterRank)
		setFilterRank(val)
		setRankTimer(true)
		getFilterNft()
	},[])

	// 更新opensea价格
	useEffect(()=>{
		const getOpenseaPrice = async ()=>{
			let params ={
				data: {
					product_id: productId,
				}
			}
			await refreshOpensea(params).then(res => { 
				console.log(res)
				if(res.code === 400) {
					return
				}
			
			}).catch(err => {
				console.log(err)
			})
		}
		getOpenseaPrice()
		const openseaTimer = setInterval(()=>{
			getOpenseaPrice()
		}, 30*1000)
		return ()=>{
			clearInterval(openseaTimer)
		}
	}, [productId])
	return (
		<div className="page_body">
			<div className="list_container">
				<div className="list_header">
					<DetailHeader pagination={pageData} callBackCurrentIndex={callBackCurrentIndex} addressTokn={Data.contract_address} iconLoading={Loading} revealed={Revealed} records={Records}/>
				</div>
				<div className="list_body">
					<div className="pane-module_fullHeight">
						<div className="table_table table-responsive">
							<Table className="table-module_container table-hover">
								<thead>
									<tr>
										<th>
											<div className="search_container">
												<div className="button_group">
													<div className="group-init">
														<div className="input-module_container">
															<input type="text"  placeholder="Search" className="input-module_input" style={{width: '100px'}} onKeyPress={searchPress} onChange={getSearchTokenId} value={seacrchTkoenId}/>  
														</div>
													</div>
													<div className="group-tail button-group-module">
														<button className="button-module_button none-module_outset outset-module_main" aria-label="Search" type="submit" onClick={getSearchData}>
															<span className="button-module_icon__uuptV">
																<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="icon-module_block" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height: "16px"}}>
																	<g>
																		<path fill="none" d="M0 0h24v24H0z"></path>
																		<path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
																	</g>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</th>
										<th>OpenSea</th>
										<th>Score	</th>
										<th>
											<OverlayTrigger
											trigger= 'click'
											rootClose={true}
											key="bottom"
											placement="bottom"
											overlay={
												<Popover id="popover-positioned">
												<Popover.Body>
													<div className="rank_panel">
														<div className="rank_body">
															<label>
																<span className="label_label text-module_muted">From</span>
																<div className="input-module_container">
																	<input type="number" placeholder="100" min={0} step="any" className="input-module_input outline-module_normal outset-module_main border-module_radius input-module_mediumMain" value={filterRank.max_rank} onChange={(e)=>{changeInputval(e, 'max_rank')}}/>
																</div>
															</label>
															<label>
																<span className="label_label text-module_muted">To</span>
																<div className="input-module_container">
																	<input type="number" placeholder="1" step="any" min={0} className="input-module_input outline-module_normal outset-module_main border-module_radius input-module_mediumMain" value={filterRank.min_rank} onChange={(e)=>{changeInputval(e, 'min_rank')}}/>
																</div>
															</label>
														</div>
														<div className="rank_footer">
															<button className="button-module_button outline-module_normal size-module_medium border-module_radius outset-module_main none-module_outset button-module_fill" type="reset" onClick={rankReset}>
																<span className="button-module_text">Reset</span>
															</button>
															<button className="button-module_button outline-module_normal size-module_medium border-module_radius outset-module_main highlight-module_outset button-module_fill" type="submit" onClick={rankApply}>
																<span className="button-module_text">Apply</span>
															</button>
														</div>
													</div>
												</Popover.Body>
												</Popover>
											}
											>
												<button className="button-module_button outline-module_normal size-module_medium outset-module_main none-module_outset" type="button">	
													<span className="button-module_text">Rank</span>
													<span className="div-module_px" style={{width: '8px' ,height: '8px'}}></span>
													<span className="button-module_icon">
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{width: '16px' ,height: '16px'}} className="icon-module_block" xmlns="http://www.w3.org/2000/svg">
															<g>
																<path fill="none" d="M0 0h24v24H0z"></path>
																<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
															</g>
														</svg>
													</span>
												</button>
											</OverlayTrigger>
											
										</th>
										<th>

											<OverlayTrigger
											trigger= 'click'
											rootClose
											key="bottom"
											placement="bottom"
											overlay={
												<Popover id="popover-positioned">
													<Popover.Body>
														<PriceComp propsData={filterRank} callFilterPrice={callFilterPrice}></PriceComp>
													</Popover.Body>
												</Popover>
												}
												>
												<button className="button-module_button outline-module_normal size-module_medium outset-module_main none-module_outset" type="button">	
													<span className="button-module_text">Price</span>
													<span className="div-module_px" style={{width: '8px' ,height: '8px'}}></span>
													<span className="button-module_icon">
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" style={{width: '16px' ,height: '16px'}} className="icon-module_block" xmlns="http://www.w3.org/2000/svg">
															<g>
																<path fill="none" d="M0 0h24v24H0z"></path>
																<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
															</g>
														</svg>
													</span>
												</button>
											</OverlayTrigger>
											
										</th>
										<th>Buy</th>
									</tr>
								</thead>
								<tbody>
								{
										Data.nfts.map((n, i) => {
												return <tr key={i}>
												<td>
													<div className="table_name" onClick={() => openDetail(n)}>
														<img className="table_projectImage" width="32" height="32" src={setIconUrl(n.images)} alt={n.token_id}/>
														{n.token_id}
													</div>
												</td>
												<td>
													<span className="table_explorer">
														<a href={`https://opensea.io/assets/${Data.contract_address}/${n.token_id}`} target="_blank" rel="noreferrer" className="link_container">
															<img src={opensea} width="16" height="16" alt="Etherscan logo" />
																<span>OpenSea</span>
														</a>
													</span>
												</td>
												<td>
												<span>{Number(n.score).toFixed(2)}</span>
												</td>
												<td>
													{
														n.rank === null?""
													:<span className="border-module_radius tag-module_container colors-module_green">{n.rank}</span>
													}
												</td>
												<td>
													<span>Ξ{n.price === null ? 0: n.price}</span>
												</td>
												<td>
													<div className="table_buy">
														{
															n.price > 0 
															? 
															<button className="button-module_button outline-module_normal size-module_medium outset-module_main highlight-module_outset" type="button" onClick={()=>{buyNft(n)}}>
																<span className="button-module_icon">
																	<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="icon-module_block" xmlns="http://www.w3.org/2000/svg" style={{width: '16px' ,height: '16px'}}>
																		<g>
																			<path fill="none" d="M0 0h24v24H0z"></path>
																			<path d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
																		</g>
																	</svg>
																</span>
																<span className="div-module_px__38Chf" style={{width: '8px' ,height: '8px'}}></span>
																<span className="button-module_text">Buy</span>
															</button>
															: ''
														}
													</div>
												</td>
											</tr>;
										})
								}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
				<NftDetaliModel
					show={modalShow}
					onHide={() => setModalShow(false)}
					data={selectData}
					contactaddress={Data.contract_address}
					traits_data={traitsData}
				/>
			</div>
		</div>
	)
}


function NftDetaliModel(props){
	// const randomBgColor = ()=>{

	// 	const colorArr = ['#991b1b','','','','','','','','','']
	// }
	return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
			dialogClassName='detail_model'
    >
			<Modal.Header closeButton>
			</Modal.Header>
      <Modal.Body>
				<div className="detail_container">
					<div className="detail_image">
						<img width="400" height="400" src={setIconUrl(props.data.images)} alt={props.data.token_id}/>
					</div>
					<div className="detail_info">
						<div className="detail_name"> 
							#{props.data.token_id} 
							{/* <button className="button-module_button outline-module_normal  border-module_radius outset-module_main none-module_outset" aria-label="share" type="button">
								<span className="button-module_icon">
									<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="icon-module_block" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
										<g>
											<path fill="none" d="M0 0h24v24H0z"></path>
											<path d="M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
										</g>
									</svg>
								</span>
							</button> */}
						</div>
						<div>
							<span>Rarity Score: </span>
							<span className="text-module_strong">{Number(props.data.score).toFixed(2)}</span>
						</div>
						<div>
							<span>Rarity Rank: </span>
							<span className="text-module_strong">{props.data.rank}</span>
						</div>
						<div>
							<span>OpenSea Price: </span>
							<span className="text-module_strong">Ξ{props.data.price === null ? '0': props.data.price}</span>
						</div>
						<div>
							<span>OpenSea Highest Offer: </span>
							<span className="text-module_strong">Ξ0</span>
						</div>
						<a className="button-module_button outline-module_normal border-module_radius outset-module_main none-module_outset button-module_fill size-module_large"  href={`https://opensea.io/assets/${props.contactaddress}/${props.data.token_id}`} target="_blank" rel="noopener noreferrer">
							<span className="button-module_text">View on OpenSea</span>
						</a>
					</div>
					<div className="pane-module_fullHeight">
						<div className="table_table table_space table-responsive">
							<Table className="table-module_container table-hover">
								<thead>
									<tr>
										<th>Trait Name</th>
										<th>Value</th>
										<th>%</th>
										<th>Score</th>
									</tr>
								</thead>
								<tbody>
								{
								
									props.traits_data.attributes !== null
									? props.traits_data.attributes.map((n, i) => {
                    console.log(n.percent === '100%')
										return <tr key={i}>
											
											<td>
											<span className="border-module_radius tag-module_container colors-module_red">{n.trait_type}</span>
											</td>
											<td>
												<span>{n.value}</span>
											</td>
											<td>
												<span>{n.percent === undefined? 0 : n.percent === '100.00%'? '1/1' : n.percent}</span>
											</td>
											<td>
												<span>{n.score === undefined? 0 : n.score}</span>
											</td>
										</tr>
									})
									: ''
								}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
      </Modal.Body>
    </Modal>
  );
}

export default DeatilComp
import React, { useState, useCallback, useEffect } from 'react';
import './index.css'
import ConnectWallet from '../../connectWallet';
import { Button } from 'react-bootstrap'
import PageSelect from '../../page/page';
// import AddProjectModal from '../../addProject';

function ListHeader({allData, addProject, pagination, callBackCurrentIndex}) {
	// const [count, setCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(1);
	// const [projectName, setProjectName] = useState('');
	const [total, setTotal] = useState(1);
	// const sendProjectName = ()=>{
	// 	console.log(projectName)
	// };
  // const writProjectName = (e) => {
	// 	if(e.keyCode === 13 && e.target.value !== ''){
	// 		setProjectName(e.target.value);
	// 	}
  // };
	useEffect(()=>{
		setPageIndex(pagination.page);
		setTotal(pagination.total)
	},[pagination.page, pagination.total])

	const changCurrentIndex = useCallback((index) =>{
		setPageIndex(index)
		callBackCurrentIndex(index)
	},[callBackCurrentIndex])
	const handelOpenModel = useCallback(()=>addProject(true),[addProject])
	return(
		<>
			<div className="header_container">
				<div className="content">
					<label>
						<span className="label_label text-module_highlightStrong">sweeper</span>
						<h1 className="header_title">On-going Projects</h1>
					</label>
					{/* <label>
						<span className="label_label">STATUS</span>
						<div className="button_group">
							
							{
								['All', 'Revealed', 'Unrevealed', 'Revealing'].map((n,i)=>{
									return <div className={ i===0 ? 'group_init': 'group-tail'} key={i}>
										<button className={['button-module_button','none-module_outset', count === i ? 'active': '', i===0?'':'outset-module_main'].join(' ')} onClick={() => setCount(i)}>{n}</button>
									</div>
								})
							}
						</div>
					</label> */}
					<span className="div-module_grow"></span>

					<label>
						<PageSelect currentIndex={pageIndex} changCurrentIndex={changCurrentIndex} total={total}></PageSelect>
					</label>
					{/* <label>
						<span className="label_label">PROJECT NAME</span>
						<div className="search_container">
								<div className="button_group">
									<div className="group-init">
										<div className="input-module_container">
											<input type="text" placeholder="" className="input-module_input" onKeyUp={writProjectName}/>
										</div>
									</div>
									<div className="group-tail button-group-module">
										<button className="button-module_button none-module_outset outset-module_main" onClick={sendProjectName}>
											<span className="button-module_icon">
												<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="icon-module_block" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height: "16px"}}>
													<g>
														<path fill="none" d="M0 0h24v24H0z"></path>
														<path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
													</g>
												</svg>
											</span>
										</button>
									</div>
								</div>
						</div>
					</label> */}
					<span className="div-module_grow"></span>
					<div className="add_container">
						<label>
							<span className="label_label"></span>
							<Button className="button-module_button outline-module_normal outset-module_main default_btn bg_green" variant="outline-secondary" onClick={handelOpenModel}>
								ADD PROJECT
							</Button>
						</label>

					</div>
					<div className="login_container">
						<label>
							<ConnectWallet/>
						</label>

					</div>
				</div>
			</div>
			{/* <AddProjectModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				type={modelType}
				address=""
				product_id=""
				supply=""
				status=""
			/> */}
		</>
	)
};




export default ListHeader;
function setIconUrl(url){
  if (url === undefined) return;
	if (url.indexOf('ipfs://') === 0 || url.indexOf('/ipfs/') > 0){
		let address = url.split('ipfs://')[1]
		return `https://cloudflare-ipfs.com/ipfs/${address}`
	} 
	// if (url.indexOf('/ipfs/') === 0) {
	// 	let address = url.split('/ipfs/')[1]
	// 	return `https://cloudflare-ipfs.com/ipfs/${address}`
	// }
	return url
}

export default setIconUrl;
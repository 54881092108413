import React, { useCallback, useEffect, useState } from "react";

function PriceComp({propsData, callFilterPrice}){
  const [props, setProps] = useState<any>({min_price: '', max_price:''})
  const [sort, setSort] = useState(1)

  useEffect(()=>{
    setProps(propsData)
    switch (propsData.sort_price) {
      case 'asc':
        setSort(0)
        break;
      case 'desc':
        setSort(2)
        break;
      default:
        setSort(1)
        break;
    }
  },[propsData])
  
  const changeInputval = useCallback((e, type)=>{
    setProps({...props, [type]: e.target.value})
  },[props])
  const priceApply = useCallback(()=>{
    callFilterPrice({...propsData, ...props})
  }, [callFilterPrice, props, propsData])
  const setPrice = useCallback(()=>{
    setProps({...props, min_price: 0.01})
    callFilterPrice({...propsData, ...props})
  },[callFilterPrice, props, propsData])

  const resetPrice = useCallback(()=>{
    const newProps = props;
    newProps.min_price = ''
    newProps.max_price = ''
    callFilterPrice(newProps)
  }, [callFilterPrice, props])


  const priceSort = useCallback((e)=>{
    const index = e.target.dataset.index;
    const type = e.target.dataset.type;
    setProps({...props,sort_price: type})
    setSort(index)
    callFilterPrice(props)
  },[callFilterPrice, props])
  return (
    <>
      <div className="price_content">
        <div>
          <div>
            <span className="label_label text-module_muted">Sort</span> 
          </div>
          <div className="button-group-module_container button-group-module_containerFill">
            {
              [{text:'Lowest First',type: "asc"}, {text:'None', type:''}, {text:'Highest First', type: 'desc'}].map((ele, i)=>{
                return <div className="group-init button-group-module_childFill" key={i} >
                  <button data-index={i} data-type={ele.type} onClick={priceSort} className={['button-module_button', 'outline-module_normal',  'outset-module_main', 'none-module_outset', 'button-module_fill', Number(sort) === Number(i)? 'active':null].join(' ')} type="button">
                    <span data-index={i} data-type={ele.type} className="button-module_text">{ele.text}</span>
                  </button>
                </div>
              })
            }
          </div>
        </div>
        <div className="price_filter border-module_weak">
          <div className="price_form">
            <div className="price_body">
              <label>
                <span className="label_label text-module_muted">Min (Ξ Ether)</span>
                <div className="input-module_container">
                  <input type="number" placeholder="None" step="any" className="input-module_input outline-module_normal outset-module_main__xm2vK  input-module_mediumMain" value={props.min_price} onChange={(e)=>{changeInputval(e, 'min_price')}}/>
                </div>
              </label>
              <label>
                <span className="label_label text-module_muted">Max (Ξ Ether)</span>
                <div className="input-module_container">
                  <input type="number" placeholder="None" step="any" className="input-module_input outline-module_normal outset-module_main  input-module_mediumMain" value={props.max_price} onChange={(e)=>{changeInputval(e, 'max_price')}}/>
                </div>
              </label>
            </div>
            <div className="price_footer">
              <button className="button-module_button outline-module_normal   outset-module_main none-module_outset button-module_fill" type="reset" onClick={resetPrice}>
                <span className="button-module_text">Reset</span>
              </button>
              <button className="button-module_button outline-module_normal   outset-module_main highlight-module_outset button-module_fill" type="submit">
                <span className="button-module_text" onClick={priceApply}>Apply</span>
              </button>
            </div>
          </div>
          <div className="price_shortcuts border-module_weak">
            <div>
              <span className="label_label text-module_muted">Shorcuts</span>
            </div>
            <button className="button-module_button outline-module_normal   outset-module_main none-module_outset button-module_fill" type="button" onClick={resetPrice}>
              <span className="button-module_text">All price</span>
            </button>
            <button className="button-module_button outline-module_normal   outset-module_main none-module_outset button-module_fill" type="button" onClick={setPrice}>
              <span className="button-module_text">Price &gt; 0.01</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceComp
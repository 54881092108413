import { useState } from 'react';
import { NxWeb3 } from '../lib/NxWeb3';


function ConnectWallet(){
	const [addr, setAddr] = useState(NxWeb3.instance.accountAddress);

    async function _connectWallet() {
        await NxWeb3.instance.connect();
        setAddr(NxWeb3.instance.accountAddress);
    }

    function addrToShow(_addr: string):string{
        return _addr.substr(0,4) + '***' + _addr.substr(_addr.length-4,4)
    }
    _connectWallet()
    return(
			<>
                {addr === '' ? '' : <span className="label_label">User</span>}
				<div className="login_container connect_wallet">
					<button className={['button-module_button','outline-module_normal','outset-module_main', addr === ''? 'highlight-module_outset': ''].join(' ')} onClick={_connectWallet}>
						<span className="button-module_text__32x4H"> {addr === '' ?  'Connect Wallet': addrToShow(addr)} </span>
					</button>
				</div>
			</>



    )
}
export default ConnectWallet;
import React  from "react";
import "./index.css";
import { CSSTransition } from 'react-transition-group';
import iconSuccess from '../../img/success.svg'
import iconInfo from '../../img/info.svg'
import iconWarning from '../../img/warning.svg'
import iconError from '../../img/error.svg'


class Notification extends React.Component<any, any>{
    private timer: NodeJS.Timeout | undefined;

    constructor(props:any){
        super(props);
        // icon路径
        let iconSrc;
        switch (this.props.type){
            case 'success':
                iconSrc = iconSuccess;
                break;
            case 'info':
                iconSrc = iconInfo;
                break;
            case 'warning':
                iconSrc = iconWarning;
                break;
            case 'error':
                iconSrc = iconError;
                break;
            default:
                iconSrc = undefined;
                break;
        }
        this.state = {
            content:"",
            verticle_btn: null,
            show:false,  //这里的show必须要设置为false,如果设置为true的话,进场没有动画.
            iconImg: iconSrc
        }
    }

    setContent = (data: any)=>{

        let obj = {
            content:data.content,
            show:true   //想要有进场动画,show需要有一个由false变成true的过程
        }
        this.setState(obj);
    }

    setHeight = (data: any) =>{
        let obj = {
            verticle_btn:data,
        }
        this.setState(obj);
    }

    fade = (flag: boolean)=>{
        this.setState({
            show:flag
        })
    }

    updateHeight = (height: any)=>{
        this.setState({
            verticle_btn:height
        })
    }

    componentDidMount(){
        this.loadTimeout((this.props.index + 1) * 2500);
    }

    loadTimeout = (n: number = 2500)=>{
        if(this.props.duration !== 0 && this.props.duration !== null && this.props.duration !== undefined) {
            n = this.props.duration;
        } else{
            return ;
        }

        this.timer = setTimeout(() => {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.fade(false);
        }, n)
    }
    close(){

    }
    componentWillUnmount(){
        if(this.timer){
            clearTimeout(this.timer);
        }
    }

    enter = ()=>{
        this.closeTimeout(this);
    }

    out = ()=>{
        this.openTimeout(this);
    }

    animationEnd(param: this) {

    }
    closeTimeout(param: this) {
    }

    openTimeout(param: this) {
    }



    render(){


        const { content,verticle_btn,show,iconImg } = this.state;


        return (
            <div className={['notification_box',this.props.className].join(' ')}>

                <CSSTransition in={show} timeout={350} classNames='fade' unmountOnExit
                               onExited={() => {
                                   this.animationEnd(this);
                               }}
                >
                    <div className="notification" style={{top:`${verticle_btn}px`}} onMouseOver={this.enter} onMouseOut={this.out}>

                        <div className={'flexBox'}>
                            {
                                this.props.type !== 'undefined'
                                    ? <div className="icon-box">
                                            <img src={iconImg} alt="" height='24'/>
                                        </div>
                                    :''
                            }
                            <div className="content">{content?content:"没有通知内容"}</div>
                        </div>
                        <span className="close" onClick={this.close}>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                            </svg>
                        </span>
                    </div>

                </CSSTransition>

            </div>

        )

    }
}

export default Notification;
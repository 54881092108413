import React from 'react';
import './App.css';
import { Link, Route, Switch } from "react-router-dom";
// import ListHeader from './components/list/header';
// import { Table } from 'react-bootstrap'
// import opensea from './img/opensea.svg'
// import etherscan from './img/etherscan.svg'
import DeatilComp from './components/detail';
import Home from './components/home';

function App() {
  return (
    <div className="App">
      <div className="slide_nav">
        <Link to="/">
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24"   className="icon-home" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M13 19h6V9.978l-7-5.444-7 5.444V19h6v-6h2v6zm8 1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20z"></path>
            </g>
          </svg>
        </Link>
      </div>
      <div className="main">
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/detail/:name/:id/:floor" component={DeatilComp}/>
        </Switch>
        {/* <div className="page_footer">
          <div className="info_container">
            <span>
              <span className="text-module_muted">Block: </span>
              <span>13453970</span>
            </span>
            <span>
              <span className="text-module_muted">Gas (Gwei): </span>
              <span className="info_prices">
                <span>
                  <span>Fast </span>
                  <span> (99%): </span>
                  <span className="tag-module_container colors-module_green">38</span>
                </span>
                <span>
                  <span>Normal </span>
                  <span> (90%): </span>
                  <span className="tag-module_container colors-module_yellow">38</span>
                </span>
                <span>
                  <span>Slow </span>
                  <span> (80%): </span>
                  <span className="tag-module_container colors-module_red">38</span>
                </span>
              </span>
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default App;

import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { addProduct, modifyStatus } from "../../axios/api";
import CallBackNews from "../notification/call";

function AddProjectModal(props) {
	const [validated, setValidated] = useState(false);
	const [address, setaddress] = useState('');
	const [supply, setSupply] = useState('');
	const [status, setstatus] = useState('Revealed');

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      let params: any;
      if(props.type!=='add'){
        params = {
          data:{
            product_id:props.data.product_id,
            status: status
          }
        }
        await modifyStatus(params).then(res => {     
          let data = {
            msg : `修改成功！`,
            type : 'success',
            className: 'asd',
            duration:  2000,
          }
          CallBackNews.NewsSend(data);

        }).catch(err => {
          let data = {
            msg : `${err}`,
            type : 'error',
            className: 'asd',
            duration:  3000,
          }
          CallBackNews.NewsSend(data);
        })
        props.onHide()

      } else {
        params = {
          data:{
            contact_address:address,
            supply: supply
          }
        }
        await addProduct(params).then(res => { 
          if(res.code === 200) {
            // rs 为0 代表已经添加过 为1 代表添加成功
            if(res.data.rs === 0){
              // alert('该项目已存在！请重新添加！')
              let data = {
                msg : `该项目已存在！请重新添加！`,
                type : 'warning',
                className: 'asd',
                duration:  3000,
              }
              CallBackNews.NewsSend(data);
            } else{
              // alert('添加成功')
              let data = {
                msg : `添加成功！`,
                type : 'success',
                className: 'asd',
                duration:  3000,
              }
              props.onHide()
              CallBackNews.NewsSend(data);
            }
          } else {
            let data = {
              msg : `${res.error.msg}`,
              type : 'error',
              className: 'asd',
              duration:  3000,
            }
            props.onHide()
            CallBackNews.NewsSend(data);
          }
        }).catch(err => {
          let data = {
            msg : `${err}`,
            type : 'error',
            className: 'asd',
            duration:  3000,
          }
          CallBackNews.NewsSend(data);
          props.onHide()
        })
      }

    }
    
    setValidated(true);
		

  };
	
	const handlAddressChange = (e) => {
		setaddress(e.target.value)
		
	}
	const handlSupplyChange = (e) => {
		setSupply(e.target.value)
	}
	const handlStatusChange = (e) => {
		setstatus(e.target.value)
	}
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
			dialogClassName='addProject_model'
			backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ADD PROJECT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
				<Form noValidate validated={validated} onSubmit={handleSubmit} >
					<Form.Group className="mb-3">
						<Form.Label>Contact address</Form.Label>
						<Form.Control type="text" placeholder="Enter Contact Address" className="input-module_input default_input input_readOnly" required onChange={handlAddressChange} readOnly={props.type==='add'? false : true} defaultValue={props.data.address}/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Supply</Form.Label> 
						<Form.Control type="number" placeholder="Enter Supply" defaultValue={props.data.supply} className="input-module_input default_input" min={0} required onChange={handlSupplyChange}/>
					</Form.Group>
					{
						props.type === 'add'
						? ''
						: <Form.Group className="mb-3">
								<Form.Label>Quantity</Form.Label>
								<div className="select_content">
									<Form.Select aria-label="Default select"  className="select-module_select none-module_outset outset-module_main select-module_mediumSelect outline-module_normal default_select" defaultValue={props.data.status === ''? 'Revealed': props.data.status} onChange={handlStatusChange}>
										<option value="Revealed">Revealed</option>
										<option value="Unrevealed">Unrevealed</option>
										<option value="Revealing">Revealing</option>
									</Form.Select>
								</div>
							</Form.Group>
					}
					<Button variant="secondary" type="submit" className="default_btn button-module_button outline-module_normal outset-module_main default_btn bg_green">
						Submit
					</Button>
				</Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddProjectModal;
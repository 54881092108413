import React, {useCallback, useState} from "react";
import {OverlayTrigger,Popover} from 'react-bootstrap'

function PageSelect({currentIndex, changCurrentIndex,total}){
  const setCutIndex = useCallback(()=>{
    changCurrentIndex(currentIndex-1);
    console.log(currentIndex)
  },[changCurrentIndex, currentIndex]);
  const setAddIndex = useCallback(()=>changCurrentIndex(currentIndex+1),[changCurrentIndex, currentIndex])
  const [inputVal, setInputVal] = useState(currentIndex)
  const handleSendClick = useCallback(()=>changCurrentIndex(inputVal),[changCurrentIndex, inputVal])

  const HandleMessageChange = (e) => {
    setInputVal(e.target.value);
  };
  
  return (
    <>
      <span className="label_label">PAGE</span>
      <div className="button_group">
        <div className="group-init button-group">
          <button className="button-module_button none-module_outset" disabled={Number(currentIndex)<=1 ? true: false} onClick={setCutIndex}>
            <span className="button-module_icon">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 8 16" className="icon-module_block" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height: "16px"}}>
                <path d="M5.5 3L7 4.5 3.25 8 7 11.5 5.5 13l-5-5 5-5z"></path>
              </svg>
            </span>
          </button>
        </div>
        <div className="group-tail group-init button-group">
          <OverlayTrigger
            trigger= 'click'
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <Popover id="popover-positioned">
                <Popover.Body>
                  <div className="search_container">
                    <div className="button_group">
                      <div className="group-init">
                        <div className="input-module_container">
                          <input min="1" type="number" placeholder="" className="input-module_input" onChange={HandleMessageChange}/>
                        </div>
                      </div>
                      <div className="group-tail button-group-module">
                        <button className="button-module_button none-module_outset outset-module_main" onClick={handleSendClick}>
                          <span className="button-module_text">Go</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            }
            >
            <button className="button-module_button none-module_outset outset-module_main">
              <span className="button-module_icon">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 13 16" className="icon-module_block" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height: "16px"}}>
                  <path d="M1.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM13 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z">
                  </path>
                </svg>
              </span>
              <span className="div-module_px__38Chf" style={{width: "8px", height: "8px"}}></span>
              <span className="button-module_text__32x4H">{Number(currentIndex)}<span>/</span>{Number(total) === 0? 1 : Number(total)}</span>
            </button>
          </OverlayTrigger>
          
        </div>
        <div className="group-tail button-group-module_childFix__1KCf0">
          <button className="button-module_button none-module_outset outset-module_main" disabled={Number(currentIndex) >=Number(total) ? true: false} onClick={setAddIndex}>
            <span className="button-module_icon">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 8 16" className="icon-module_block" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{width: "16px", height: "16px"}}>
                <path d="M7.5 8l-5 5L1 11.5 4.75 8 1 4.5 2.5 3l5 5z"></path>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </>
  )
}


export default PageSelect;
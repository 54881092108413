/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useState,useEffect, useCallback} from 'react';
import { Link, useLocation } from "react-router-dom";
import ListHeader from '../list/header';
import { Table } from 'react-bootstrap'
import opensea from '../../img/opensea.svg'
import etherscan from '../../img/etherscan.svg'
import { apiArticleEdit } from '../../axios/api'
import AddProjectModal from '../addProject';

function Home() {
	let location = useLocation();
	const [projectdData, setProjectsData] = useState<any>({projects:[]})
	const [modalShow, setModalShow] = useState(false);
	const [modelType, setModelType] = useState('add')
	const [ChangeStatusAddress, setChangeStatusAddress] = useState({contact_address:'',product_id:'',supply:'',status:'',address:''})
	const [pageData, setPage] = useState({page:1,limit: 50, total: 0})
	const getData = async ()=> {
		
		const params = {
			page: Number(pageData.page -1),
			limit: pageData.limit
		}
		await apiArticleEdit(params).then(res => { 
			// 获取数据成功后的其他操作
			setProjectsData(res.data)
			const paginationData:any = {
				page: Number(res.data.page +1),
				limit: res.data.limit,
				total: res.data.total_pages
			}
			setPage(paginationData)
		}).catch(err => {
			console.log(err)
		})
	}
	useEffect(()=>{
		getData()
		let Timer;
		Timer = setInterval(() => {
			getData()
		}, 15000)
		return ()=>{
			clearInterval(Timer)
		}
	}, [modalShow, pageData.page, location.pathname])


	function formDate(dateForm) {
		if (dateForm === "") {  //解决deteForm为空传1970-01-01 00:00:00
				return "";
		}else{
				var dateee = new Date(dateForm ).toJSON();
				var date = new Date(+new Date(dateee)+ 8 * 3600 * 1000).toISOString().replace(/T/g,' ').replace(/\.[\d]{3}Z/,'');
				return date;
		}
	}

	function getClass(status){
		switch (status) {
			case 'Unrevealed':
				return 'colors-module_indigo';
			case 'Revealed':
				return 'colors-module_green';
			default:
				return "colors-module_red";
		}
	}

	
	function handelOpenModel(type, n){
		setModalShow(true)
		setModelType(type)
		setChangeStatusAddress(n)
	}
	const returnAddProject = useCallback((val)=>{
		setModalShow(val);
		setModelType('add')
		const params = {contact_address:'',product_id:'',supply:'',status:'',address:''};
		setChangeStatusAddress(params)
	},[])

	const callBackCurrentIndex= useCallback((val)=>{
		const paginationData:any = {
			page: val,
			limit: pageData.limit,
			total: pageData.total
		}
		setPage(paginationData)
	},[])
	return (
		<>
			<div className="page_body">
          <div className="list_container">
            <div className="list_header">
              <ListHeader allData={projectdData} addProject={returnAddProject} pagination={pageData} callBackCurrentIndex={callBackCurrentIndex}/>
            </div>
            <div className="list_body">
              <div className="list_table">
								<div className="pane-module_fullHeight">
									<div className="table_table">
									<Table className="table-module_container">
										<thead>
											<tr>
												<th>Name</th>
												<th>Status</th>
												<th>Floor	</th>
												<th>Supply</th>
												<th>Explorer</th>
												<th>Revealed At</th>
											</tr>
										</thead>
										<tbody>
										{
											projectdData.projects === null
											? ''
											:
												projectdData.projects.map((n:any, i:number) => {
														return <tr key={i}>
														
														<td>
															<Link to={`/detail/${n.name}/${n.product_id}/${n.floor === null ? 0 : n.floor}`}>
																<img className="table_projectImage" width="32" height="32" src={n.logo === null ? require('../../img/placeholder.png').default: n.logo}/>
																{n.name}
															</Link>
														</td>
														<td>
															<span className="table_status"onClick={()=>{handelOpenModel('change', n)}}>
																<span className={['tag-module_container', getClass(n.status)].join(' ')}>{n.status}</span>
															</span>
														</td>
														<td>
															<span>Ξ{n.floor === null ? 0 : n.floor}</span>
														</td>
														<td>
															<span>{n.supply}</span>
														</td>
														<td>
														<span className="table_explorer">

														
															<a href={`https://etherscan.io/address/${n.contact_address}#readContract`} target="_blank" rel="noreferrer" className="link_container">
																<img src={etherscan} width="16" height="16" alt="Etherscan logo" />
																<span>Etherscan</span>
															</a>
															
															<a href={`https://opensea.io/assets/${n.contact_address}/1`} target="_blank" rel="noreferrer" className="link_container">
																<img src={opensea} width="16" height="16" alt="OpenSea logo" />
																	<span>OpenSea</span>
																</a>
															</span>
														</td>
														<td>
															<span>{formDate(n.update_ts)}</span>
														</td>
													</tr>;
												})
										}
											
											
										</tbody>
									</Table>
									</div>
								</div>
							</div>
            </div>
          </div>
        </div>
				<AddProjectModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				type={modelType}
				data={ChangeStatusAddress}
			/>
		</>
	)
}


export default Home
import service from './axios'

function apiArticleEdit(param){
  return new Promise((resolve, reject) => {
    service("get",'list',param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function apiDetail(param){
  return new Promise((resolve, reject) => {
    service("get",'nfts', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function addProduct(param) {
  return new Promise((resolve, reject) => {
    service("post",'add_product', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function modifyStatus(param) {
  return new Promise((resolve, reject) => {
    service("post",'modify_status', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function queryNft(param) {
  return new Promise((resolve, reject) => {
    service("get",'query_nft', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function searchNft(param) {
  return new Promise((resolve, reject) => {
    service("post",'search', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function filterRankPrice(param) {
  return new Promise((resolve, reject) => {
    service("get",'filter', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function filterNft(param) {
  return new Promise((resolve, reject) => {
    service("get",'nfts_v2', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function refreshOpensea(param) {
  return new Promise((resolve, reject) => {
    service("post",'opensea', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function feedSplitsData(param) {
  return new Promise((resolve, reject) => {
    service("post",'splits', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

function feedAllsData(param) {
  return new Promise((resolve, reject) => {
    service("post",'alls', param).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  }) 
}

export {
  apiArticleEdit,
  apiDetail,
  addProduct,
  modifyStatus,
  queryNft,
  searchNft,
  filterRankPrice,
  filterNft,
  refreshOpensea,
  feedSplitsData,
  feedAllsData
}
import { Network, OpenSeaPort } from 'opensea-js';
import Web3 from 'web3'

export class NxWeb3 {
    private static _inst: NxWeb3;
    static get instance(): NxWeb3 {
        return this._inst || (this._inst = new NxWeb3());
    }

    //@ts-ignore
    web3: Web3;

    isInited: boolean;

    accountAddress: string = '';

    balance: number = 0;

    get ethBalance(): number {
        return this.dether(this.balance);
    }

    get isConnected(): boolean  {
        return this.accountAddress !== '';
    }

    contractAddress: string = '0xeB4f69AF852513C1D19552E7de62bA95d97367AF';

    contractAbi : [Object] = [
        {
            "inputs": [],
            "name": "publicMint",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }
    ];

    constructor() {  
        console.log('init')
        this.isInited = this.init();
    }

    opseaConnect: OpenSeaPort | undefined;

    init():boolean{
        try {
            //@ts-ignore
            if (!ethereum || !ethereum.isMetaMask) {
                alert('Please install MetaMask.')
                return false
            }
            //@ts-ignore
            if (!window.web3) {
                return false;
            }
            //@ts-ignore
            this.web3 = new Web3(window.ethereum);
            //@ts-ignore
            window.ethereum.enable();
            // 获取最新区块
            // this.web3.eth.getBlockNumber().then(console.log)
            // 根据最近几个区块，计算平均Gas价格
            // this.web3.eth.getGasPrice().then((result) => {
            //     console.log("wei: " + result)
            //     console.log("ether: " + this.web3.utils.fromWei(result, 'ether'))
            // })
            this.connectOPensea(this.web3.currentProvider)

            return true;
        } catch (error) {
            return false;
        }    
    }

    async connect(){
        try {
            //@ts-ignore
            const rst = await this.web3.eth.getAccounts();

            // console.log(rst);
            // 添加判断： 未连接/拒绝连接/连接手动断开时init;
            if(rst.length !== 0){
                this.accountAddress = rst[0];
                this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress))
            } else {
                this.init()
            }
        } catch (error) {
            console.log(error);
        }
    }

    async publicMint(): Promise<boolean>{
        try {
            const factoryContract = new this.web3.eth.Contract(
                //@ts-ignore
                this.contractAbi,
                this.contractAddress
            );
            await factoryContract.methods
            .publicMint()
            .send({ from: this.accountAddress ,  value: this.ether(0.1),});
            return true;
        } catch (error) {
            return false;
        }
    }
    // 连接openSea，获取provider
    connectOPensea(provider){
        try {
            this.opseaConnect = new OpenSeaPort(provider, {
                networkName: Network.Main
            })
            // console.log('this.opseaConnect', this.opseaConnect)
        } catch (error) {
            // console.log('连接openSea，获取provider', error)
        }
        
    }

    ether(eth:number):number{
        return 1000000000000000000 * eth;
    }

    dether(eth:number):number{
        return parseFloat((eth / 1000000000000000000).toFixed(2));
    }
}
import Notification from "./Notification";
import ReactDOM from "react-dom";
import React from "react";


/**
 * 鼠标划入通知框
 */
const closeTimeout = ()=>{
    notifications.forEach((v)=>{
        clearTimeout(v.instance.timer);
    })
}


/**
 * 鼠标移出通知框
 */
const openTimeout = ()=>{
    notifications.forEach((v,i)=>{
        v.instance.loadTimeout((i+1)*3000);
    })

}

//通知的集合
const notifications = [];

const createNotification = (data)=>{

    let DIV = document.createElement("DIV");

    document.body.appendChild(DIV);

    const notify = ReactDOM.render(<Notification duration={data.duration} className={data.className} type={data.type} notifications={notifications} index={notifications.length}/>,DIV);

    notifications.push({
        dom:DIV,
        instance:notify
    });

    return notify;

}

//销毁组件
const destory = (notify)=>{

    let index = notifications.findIndex((v)=>{
        return v.instance === notify;
    })

    if(index === -1){
        return false;
    }

    const dom = notifications[index].dom;

    ReactDOM.unmountComponentAtNode(dom);

    document.body.removeChild(dom);

    notifications.splice(index,1);


}

/**
 * 重新计算每个组件距离底部的高度
 */
const countVerticleHeight = ()=>{

    const padding = 16;

    notifications.forEach((v,index)=>{
        let domes = v.dom.getElementsByClassName('notification')[0];
        let doHeight = domes.offsetHeight;

        const verticle_btn = (index+1)*padding + doHeight * index;

        v.instance.updateHeight(verticle_btn);

    })

}

/**
 * 计算出每个通知框距离底部的高度和内容
 */
const getData = (content,notify)=>{
    // const index =
    notifications.findIndex((v)=>{
        return v.instance === notify;
    })
    return {
        content,
    }
}

const getHeight = (notify)=>{
    const index = notifications.findIndex((v)=>{
        return v.instance === notify;
    })

    const padding = 16;
    let domes = notifications[index].dom.getElementsByClassName('notification')[0];
    let doHeight = domes.offsetHeight;
    return (index + 1) * padding + index * doHeight;
}


const CallBackNews = {

    NewsSend: function (data) {  //发送通知
        // 创建外层壳
        const notify = createNotification(data);

        // 常见内部内容
        notify.setContent(getData(data.msg, notify));

        // 计算高度，定位
        setTimeout(() => {
            notify.setHeight(getHeight(notify));
        }, 200)

        // 点击关闭
        notify.close = () => {
            notify.fade(false);
        }
        notify.animationEnd = (notify) => {
            destory(notify);
            countVerticleHeight();
        }
        notify.closeTimeout = closeTimeout;
        notify.openTimeout = openTimeout;
    }

}

export default CallBackNews;
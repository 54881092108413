import React, {useState, useCallback, useEffect} from 'react';
import ConnectWallet from '../connectWallet';
import './header.css'
import PageSelect from '../page/page';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

function DetailHeader({pagination, callBackCurrentIndex, addressTokn,iconLoading, revealed, records}) {
	const routeParams:any = useParams();
	const ruleName = routeParams.name; // 获取ruleName
	const FloorPrice = routeParams.floor; // 获取ruleName，即获取项目floor
	const [pageIndex, setPageIndex] = useState(1);
	const [total, setTotal] = useState(1);
	const [limt, setLimt] = useState(50);
	const [supplyData, setSupplyData] = useState({revealed: 0,showing: 0,supply:0,floor:0,oneDayVol: 0});
	const changCurrentIndex = useCallback((index) =>{
		setPageIndex(index)
		callBackCurrentIndex(index, pagination.limit)
	},[callBackCurrentIndex, pagination.limit])
	useEffect(()=>{
		setPageIndex(pagination.page);
		setTotal(pagination.total)
		setLimt(pagination.Limt)
		const  getSupply =async () => {
			if(addressTokn==='') return
			const options = {method: 'GET'};
			const url = `https://api.opensea.io/api/v1/asset/${addressTokn}/1/`
			try {
				let response = await fetch(url, options);
				if(response.status === 200){
						let data = await response.json();
					let collectionStats = data.collection.stats;
					const params = {
						revealed: 0,
						showing: 0,
						supply: collectionStats.total_supply,
						floor: collectionStats.floor_price,
						oneDayVol: collectionStats.one_day_volume
					}
					setSupplyData(params)
				}
			
			} catch (error) {
			}
		}
		getSupply()
	},[addressTokn, pagination.Limt, pagination.page, pagination.total])
	const changeLimit = useCallback((e)=>{
		setLimt(e.target.value)
		callBackCurrentIndex(1, e.target.value, )
	},[callBackCurrentIndex])

	const reactBtn = useCallback(()=>{
		callBackCurrentIndex(1, 50, 'reset')
		setLimt(50)
	},[callBackCurrentIndex])
	
    return(
			<>
				<div className="header_container">
					<div className="content">
						<label>
							<span className="label_label text-module_highlightStrong">sweeper</span>
							<h1 className="header_title">{ruleName}</h1>
						</label>
						<label>
								<span className="label_label">Got</span>
								<div className="header_value">
										<span className=" tag-module_container colors-module_green">{records}</span>
								</div>
						</label>
						<label>
								<span className="label_label">Revealed</span>
								<div className="header_value">
										<span className=" tag-module_container colors-module_green">{revealed}</span>
								</div>
						</label>
						{/* <label>
								<span className="label_label">Showing</span>
								<div className="header_value">
										<span className=" tag-module_container">{supplyData.showing}</span>
								</div>
						</label> */}
						<label>
								<span className="label_label">Supply</span>
								<div className="header_value">
										<span className=" tag-module_container">{supplyData.supply}</span>
								</div>
						</label>
						<label>
								<span className="label_label">Floor</span>
								<div className="header_value">
										<span className=" tag-module_container">Ξ{Number(FloorPrice).toFixed(2)}</span>
								</div>
						</label>
						<label>
								<span className="label_label">1 Day vol</span>
								<div className="header_value">
										<span className=" tag-module_container colors-module_green">Ξ{(supplyData.oneDayVol).toFixed(2)}</span>
								</div>
						</label>
						<span className="div-module_grow"></span>
						<label>
							<div className="loading_icon">
								<Spinner hidden={!iconLoading} animation="border" as="span" role="status" variant="success">
								</Spinner>
							</div>
						</label>
						<label>
							<PageSelect currentIndex={pageIndex} changCurrentIndex={changCurrentIndex} total={total}></PageSelect>
						</label>
						<label>
							<span className="label_label">Supply</span>
							<div className="select_content">
								<select className="select-module_select none-module_outset outset-module_main select-module_mediumSelect outline-module_normal" onChange={changeLimit}  value={limt}>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
							</div>
						</label>
						<button className="button-module_button outline-module_normal size-module_medium  outset-module_main none-module_outset" type="button" onClick={reactBtn}>
							<span className="button-module_text">Reset</span>
						</button>
						<span className="div-module_grow"></span>
						<div className="login_container">
							<label>
								<ConnectWallet/>
							</label>
						</div>
					</div>
				</div>
			</>
    )
};

export default DetailHeader;